body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pg-white {
    background-color: #FFF;
}

.pg-blue1 {
    background-color: #0B1F3F;
}

.pg-blue2 {
    background-color: #09097D;
}

.pg-red {
    background-color: #FF0054;
}

.pg-gradient180 {
    background: linear-gradient(180deg, #09097D 16%, #FF0054 99%);
}

.pg-gradient90 {
    background: linear-gradient(90deg,#001BA9 16%,#0027f9 99%)
}

.pg-gradient0 {
    background: linear-gradient(90deg, #09097D 16%, #FF0054 99%)
}

.prevent_word_overflow {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.pg-cover {
    background-repeat: no-repeat;
    background-size: auto;
    height: 100vh;
}

.btn-method:hover,
.btn-method.active {
    background: transparent;
    box-shadow: 5px 5px 5px -2px rgb(0 0 0 / 50%);
    cursor: pointer;
}

.card {
    border: none;
}

.card-header:first-child {
    border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
}

.card-footer:last-child {
    border-radius: 0 0 calc(1rem - 1px) calc(1rem - 1px);
}

.card-header,
.card-footer {
    background-color: inherit;
    border-bottom: 0;
}

.card-header {
    background: #0027f9;
    color: #ffffff;
    font-weight: 700;
}

.card-body,
.card-footer {
    background-color: #ffffff;
    border: 1px solid #cecece;
}

.card-body {
    border-bottom: none;
}

.card-footer {
    border-top: none;

}

a.btn-link {
    text-decoration: none;
}

.dropdown-language button,
.dropdown-language button:hover,
.dropdown-language button:focus,
.show>.btn-secondary.dropdown-toggle {
    background-color: transparent;
    color: inherit;
    border-color: inherit;
}

.secondary.dropdown-toggle:focus {
    box-shadow: '#fff';
}

.btn-blue1,
.btn-blue1:hover,
.btn-blue1:active,
.btn-blue1:focus {
    background-color: #0B1F3F;
    color: #fff;
}

.btn-outline-secondary:hover {
    background-color: inherit;
    color: #0b1f3f;
    border-color: #0b1f3f;
}

.btn-method {
    border-color: #cdcdcd;
}

.btn-primary {
    color: #fff;
    background-color: #0027F8;
    border-color: #0027F8;
}

.btn-primary:hover,
.btn-primary:active {
    background-color: #001BA9;
    border-color: #0062cc;
}

label {
    color: #888;
    position: relative;
    top: 17px;
    left: 12px;
    background: #fff;
    font-size: 12px;
}

.form-control {
    padding: 0.75rem 0.75rem;
}

.btn-back {
    color: #c3c3c3;
    text-decoration: none;
}

.btn-back:hover,
.btn-back:active,
.btn-back:focus {
    color: #999;
    box-shadow: none;
}

.method-btn {
    background: #fff;
    box-shadow: 0px 5px 5px -2px rgb(0 0 0/50%);
    cursor: pointer;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

.header__security__text {
    font-family: "Open Sans Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ABABAB;
    padding: 0 6px;
}